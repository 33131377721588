.homepage {
  background-image: url("../../assets/images/stars_h_720.png");
  min-height: 720px; }
  .homepage .hero-body {
    width: 500px;
    max-height: 460px;
    background-color: black;
    background: #050505;
    /* Fallback for older browsers without RGBA-support */
    background: rgba(5, 5, 5, 0.5);
    margin: auto; }
  .homepage a:hover {
    color: #3273dc;
    cursor: pointer;
    text-decoration: underline; }
  .homepage .form-container {
    margin: auto;
    width: 350px; }
  .homepage form {
    max-width: 100%; }
