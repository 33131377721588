html {
  height: 100%; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: flex;
  flex-direction: column; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .App .main-container {
    flex: 1 1; }
  .App .container.space-bg {
    background-color: white;
    background-image: url(/static/media/stars_h_720.cd4c84a6.png);
    min-height: 720px; }

.container h2 {
  font-weight: bold; }

.container .content {
  text-align: left; }

.flag-card {
  text-align: left;
  font-size: 1em;
  cursor: pointer; }
  .flag-card .flag-info {
    font-size: .8em; }
  .flag-card h5 {
    font-weight: bold; }
  .flag-card .tags {
    font-size: .8em; }
  .flag-card p span {
    font-weight: bold; }

.success-message-container {
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  color: #59D389;
  text-align: left;
  margin-bottom: 1em; }
  .success-message-container .success-message {
    display: flex !important;
    align-items: center;
    margin-bottom: 7px;
    -webkit-animation: input-error-enter .4s ease;
            animation: input-error-enter .4s ease; }
    .success-message-container .success-message .icon-check {
      color: #59D389;
      font-size: 16px;
      margin-right: 12px;
      font-weight: bold;
      transition: .2s ease; }
    .success-message-container .success-message .message {
      flex: 1 1; }
    .success-message-container .success-message.from-server {
      width: 100%;
      padding: 8px 10px;
      font-size: 12px;
      border-radius: 6px;
      color: #59D389;
      border: solid 1px #59D389;
      background-color: rgba(89, 211, 137, 0.12); }

@-webkit-keyframes input-error-enter {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0px); } }

@keyframes input-error-enter {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0px); } }

.error-message-container {
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  color: #9d9d9d;
  text-align: left; }
  .error-message-container .error-message {
    display: flex !important;
    align-items: center;
    margin-bottom: 7px;
    -webkit-animation: input-error-enter .4s ease;
            animation: input-error-enter .4s ease; }
    .error-message-container .error-message .icon-check {
      color: #9d9d9d;
      font-size: 16px;
      margin-right: 12px;
      font-weight: bold;
      transition: .2s ease; }
    .error-message-container .error-message .message {
      flex: 1 1; }
    .error-message-container .error-message.from-server {
      width: 100%;
      padding: 8px 10px;
      font-size: 12px;
      border-radius: 6px;
      color: #000;
      border: solid 1px #CCC;
      background-color: #EEE; }
      .error-message-container .error-message.from-server.error {
        color: #f96153;
        border: solid 1px #f96153;
        background-color: rgba(249, 97, 83, 0.12); }

@-webkit-keyframes input-error-enter {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0px); } }

@keyframes input-error-enter {
  0% {
    opacity: 0;
    transform: translateX(30px); }
  100% {
    opacity: 1;
    transform: translateX(0px); } }

.cohortDetails h4 {
  font-size: 1rem; }

.cohortDetails h5 {
  font-size: .8rem; }

.CohortCriterion .delete-btn {
  cursor: pointer;
  align-self: flex-end; }

.container.flag-details h2 {
  font-weight: bold; }
  .container.flag-details h2 span {
    font-weight: normal; }

.container.flag-details .content {
  text-align: left; }
  .container.flag-details .content .title.is-3 {
    margin-bottom: 0; }

.flags-list-container table tr {
  cursor: pointer; }

.flags h2.flags-tile {
  font-size: 1.5em; }

.flags .nb {
  font-weight: normal; }

.navbar .navbar-item {
  border-bottom: 2px solid #ffffff; }

.navbar .navbar-item img {
  max-height: 2rem; }

.navbar .navbar-item.email {
  color: #7d7d7d; }

.navbar .navbar-start a.navbar-item.active, .navbar .navbar-start a.navbar-item:hover {
  background-color: #fafafa;
  border-bottom: 2px solid #00D1B2; }

.auth h2 {
  font-size: 1.5em; }

.auth .required {
  font-size: .7em; }

.auth form.register {
  max-width: 100%; }

.auth .password-rules {
  margin-top: 2.7em; }

.auth .password-list {
  font-size: .7em;
  color: #666; }

.auth form {
  max-width: 50%; }

.auth .container h2 {
  font-weight: normal; }

.auth form {
  max-width: 50%; }

.auth h2 {
  font-size: 1.5em; }

.auth .required {
  font-size: .7em; }

.auth form.register {
  max-width: 100%; }

.auth .password-rules {
  margin-top: 2.7em; }

.auth .password-list {
  font-size: .7em;
  color: #666; }

.auth h2 {
  font-size: 1.5em; }

.auth form {
  max-width: 50%; }

.container.workspace-details h2 {
  font-size: 1.5em;
  font-weight: normal; }

.container.workspace-details .content {
  text-align: left; }
  .container.workspace-details .content .workspace-subhead {
    font-weight: bold; }
    .container.workspace-details .content .workspace-subhead span {
      font-weight: normal; }
  .container.workspace-details .content .title.is-3 {
    font-size: 1.5em; }
  .container.workspace-details .content .section {
    padding: 2rem 1.5rem;
    border-radius: 5px;
    border: 1px solid #dedede; }
  .container.workspace-details .content .workspace-section p {
    font-size: .8em; }
  .container.workspace-details .content .file-details h2, .container.workspace-details .content .file-details h4 {
    font-size: 1em; }
  .container.workspace-details .content .file-details p {
    font-size: .8em; }


.card {
  cursor: pointer;
  max-width: 420px; }
  .card:hover {
    background-color: #fafafa; }
  .card h2 {
    font-weight: bold; }
  .card .workspace-card-label {
    font-size: .7em; }

.container.workspaces .section {
  padding: 1rem 1.5rem; }

.container.workspaces h2 {
  font-size: 1.2em; }

.container.workspaces h2.workspaces-title {
  font-size: 1.5em; }

.homepage {
  background-image: url(/static/media/stars_h_720.cd4c84a6.png);
  min-height: 720px; }
  .homepage .hero-body {
    width: 500px;
    max-height: 460px;
    background-color: black;
    background: #050505;
    /* Fallback for older browsers without RGBA-support */
    background: rgba(5, 5, 5, 0.5);
    margin: auto; }
  .homepage a:hover {
    color: #3273dc;
    cursor: pointer;
    text-decoration: underline; }
  .homepage .form-container {
    margin: auto;
    width: 350px; }
  .homepage form {
    max-width: 100%; }

