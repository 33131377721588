.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .App .main-container {
    flex: 1; }
  .App .container.space-bg {
    background-color: white;
    background-image: url("assets/images/stars_h_720.png");
    min-height: 720px; }
